const env = process.env.NODE_ENV;
const SERVER = {
    development:{
        SERVER_URL:'https://dev.juicy3.com/api/',
    },
    production:{
        SERVER_URL:'https://production.juicy3.com/api/',
    }
}

export default SERVER[env];