import axiosApi,{setHeader} from "@/api/root.api";

export const getListApi = async ()=>{
    const urlEnd = 'posts';
    try{
        const response = await axiosApi.get(urlEnd);
        const result = response.data;
        return result;
    }catch(err){
        return err;
    }
};