import axiosApi,{setHeader} from "@/api/root.api";

export const loginApi = async (body)=>{
    const urlEnd = 'login';
    setHeader('Content-Type','application/json');
    try{
        const response = await axiosApi.post(urlEnd,body);
        const result = response.data;
        return result;
    }catch(err){
        return err;
    }
};