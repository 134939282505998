<template>
  <header>
    <img src="~@/assets/images/Header/logo.svg" class="logo" alt="">
    <a href="#" @click="logoutEvent" class="logout">登出</a>
  </header>
</template>

<script>

//vue-router
import {useRouter} from 'vue-router';

//plugin
import Cookies from 'js-cookie';

export default {
  setup:()=>{

    const router = useRouter();

    const logoutEvent = (e)=>{
      e.preventDefault();
      Cookies.remove('token');
      router.push({path:'/'});
    }

    return {logoutEvent}
  }
}
</script>

<style lang="scss" scoped>
  header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #ccc;
    .logo{
      width: 200px;
    }
    .logout{
      display: block;
      background-color: #1CB4BF;
      width: 75px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      border-radius: 3px;
      text-decoration: none;
    }
  }
</style>