import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import Login from '../views/Login';
import BlogList from '../views/BlogList';
import BlogInner from '../views/BlogInner';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/list',
    name: 'BlogList',
    component: BlogList
  },
  {
    path: '/blog',
    name: 'BlogInner',
    component: BlogInner
  },
  {
    path: '/editBlog/:id',
    name: 'editBlog',
    component: BlogInner
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
