<template>
  <section class="inner">
    <form>
      <div class="formCon">
        <div class="formLeft">
          專案名稱<br />
          <input
            @change="changeStatusEvent"
            type="text"
            :class="{ err: name.isErr }"
            v-model="name.value"
          />
        </div>
        <div class="formRight">
          專案類型<br />
          <select
            v-model="type.value"
            :class="{ err: type.isErr }"
            name=""
            id=""
          >
            <option value="地方創生合作">地方創生合作</option>
            <option value="觀光整合行銷">觀光整合行銷</option>
            <option value="藝術文化推廣">藝術文化推廣</option>
            <option value="公共政策品牌加值">公共政策品牌加值</option>
            <option value="品牌加值策略執行">品牌加值策略執行</option>
          </select>
        </div>
      </div>
      <div class="formCon">
        <div class="formLeft">
          專案連結<br />
          <input
            v-model="link.value"
            :class="{ err: link.isErr }"
            placeholder="記得要加http://或https://"
            type="text"
          />
        </div>
        <div class="formRight">
          專案圖片<br />
          <label for="file">
            <div :class="['inputFileDiv', { err: fileImg.isErr }]">
              {{ fileName }}
            </div>
            <input id="file" @change="fileChange" type="file" />
          </label>
        </div>
      </div>
      <input @click="postEvent" type="submit" value="送出" />
      <router-link class="backBtn" to="/list">返回</router-link>
    </form>
  </section>
</template>

<script>
//vue
import { onMounted, reactive, ref, watch } from "@vue/runtime-core";
import { useRouter, useRoute } from "vue-router";

//env
import SERVER from "@/constants/server";

//api
import { createPosts } from "@/api/createPosts";
import { getPosts } from "@/api/getPosts";
import { editPosts } from "@/api/editPosts";

//plugin
import Cookies from "js-cookie";

export default {
  setup: () => {
    const router = useRouter();
    const route = useRoute();

    const name = reactive({ value: "", isErr: false });
    const type = reactive({ value: "", isErr: false });
    const link = reactive({ value: "", isErr: false });
    const fileImg = reactive({ value: null, isErr: false });
    const fileName = ref("請選擇您的檔案");
    const id = route.params.id;

    let isFalseValue = 0;

    watch(
      () => name.value,
      () => {
        name.isErr = false;
        isFalseValue = 0;
      }
    );

    watch(
      () => type.value,
      () => {
        type.isErr = false;
        isFalseValue = 0;
      }
    );

    watch(
      () => link.value,
      () => {
        link.isErr = false;
        isFalseValue = 0;
      }
    );

    watch(
      () => fileImg.value,
      () => {
        if (fileName.value === "請選擇您的檔案") {
          fileImg.isErr = false;
          isFalseValue = 0;
        }
      }
    );

    onMounted(async () => {
      if (id !== undefined) {
        const getPostsRes = await getPosts(id);
        if (getPostsRes.status === "true") {
          name.value = getPostsRes.data.name;
          type.value = getPostsRes.data.type;
          link.value = getPostsRes.data.link;
          fileName.value = getPostsRes.data.image;
          // SERVER.URL + getPostsRes.data.image
        }
      }
    });

    const postEvent = async (e) => {
      e.preventDefault();

      if (name.value === "") {
        name.isErr = true;
        isFalseValue = 1;
      }

      if (type.value === "") {
        type.isErr = true;
        isFalseValue = 1;
      }

      if (link.value === "") {
        link.isErr = true;
        isFalseValue = 1;
      }

      if (fileName.value === "請選擇您的檔案") {
        fileImg.isErr = true;
        isFalseValue = 1;
      }

      const sendData = new FormData();
      sendData.append("name", name.value);
      sendData.append("type", type.value);
      sendData.append("link", link.value);
      sendData.append("token", Cookies.get("token"));

      if (isFalseValue === 0) {
        try {
          if (id !== undefined) {
            // sendData.append('image',fileImg.value);
            sendData.append("_method", "patch");
            if (fileImg.value !== null) {
              sendData.append("image", fileImg.value);
            }
            const editPostsRes = await editPosts(id, sendData);
            // console.log(editPostsRes)
          } else {
            sendData.append("image", fileImg.value);
            const createPostsRes = await createPosts(sendData);
          }
          router.push({ path: "/list" });
        } catch (err) {
          // console.log(err);
        }
      }
    };

    const fileChange = (e) => {
      fileImg.value = e.target.files[0];
      fileName.value = e.target.files[0].name;
      isFalseValue = 0;
    };

    return { name, type, link, fileImg, postEvent, fileChange, fileName };
  },
};
</script>

<style scoped lang="scss">
.inner {
  padding-top: 90px;
  max-width: 870px;
  margin: 0 auto;
  .formCon {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    padding-bottom: 30px;
    input,
    select,
    .inputFileDiv {
      width: 400px;
      margin-top: 10px;
      height: 40px;
      border: 1px solid #ccc;
      box-sizing: border-box;
      padding: 0 10px;
      line-height: 40px;
      background-color: #fff;
    }
    input.err,
    select.err,
    .inputFileDiv.err {
      border: 1px solid red;
    }
    .inputFileDiv {
      cursor: pointer;
    }
    #file {
      display: none;
    }
  }
  input[type="submit"],
  .backBtn {
    width: 70px;
    height: 35px;
    text-align: center;
    border-radius: 3px;
    color: #fff;
    line-height: 35px;
    background-color: #1cb4bf;
    font-size: 14px;
    border: none;
    cursor: pointer;
    vertical-align: top;
    &:hover {
      opacity: 0.8;
    }
  }
  .backBtn {
    display: inline-block;
    margin-left: 10px;
    vertical-align: top;
    background-color: #aaa;
    text-decoration: none;
  }
}
</style>
