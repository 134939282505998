<template>
  <section class="inner">
    <div class="top">
      <div class="selectCon">
        目前專案：
        <select name="" v-model="typeSelect" class="kindSelect" id="">
          <option value="">全部</option>
          <option value="地方創生合作">地方創生合作</option>
          <option value="觀光整合行銷">觀光整合行銷</option>
          <option value="藝術文化推廣">藝術文化推廣</option>
          <option value="公共政策品牌加值">公共政策品牌加值</option>
          <option value="品牌加值策略執行">品牌加值策略執行</option>
        </select>
      </div>
      <router-link to="/blog" class="addProject">
        <fa icon="circle-plus" />
        新增專案
      </router-link>
    </div>
    <div class="block">
      <div v-for="(item, index) in blogFilter" :key="item.id" class="list">
        <div class="name">{{ item.name }}</div>
        <a @click="deleteEvent(item.id)" class="delete">
          刪除<fa icon="trash" />
        </a>
        <router-link :to="`/editBlog/${item.id}`" class="edit">
          編輯<fa icon="pencil" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
//vue
import { computed, onMounted, reactive, ref, watch } from "@vue/runtime-core";

//api
import { getListApi } from "@/api/getListApi";
import { deleteApi } from "@/api/deleteApi";

//plugin
import Cookies from "js-cookie";

export default {
  setup: () => {
    const blogList = reactive({ items: [] });
    const typeSelect = ref("");
    const typeKind = ref("");

    onMounted(async () => {
      try {
        const getListApiRes = await getListApi();
        blogList.items = getListApiRes.data;
        // console.log(getListApiRes.data)
      } catch (err) {}
    });

    const blogFilter = computed(() => {
      if (typeSelect.value === "") {
        return (blogFilter.value = blogList.items);
      } else {
        return blogList.items.filter((item) => item.type === typeSelect.value);
      }
    });

    const deleteEvent = async (id) => {
      const tokenKey = Cookies.get("token");
      // console.log(tokenKey,'id',id)
      // return;
      try {
        const deleteApiRes = await deleteApi(
          { token: tokenKey, _method: "delete" },
          id
        );
        const getListApiRes = await getListApi();
        blogList.items = getListApiRes.data;
      } catch (err) {
        console.log("錯誤");
        // console.log(err);
      }
    };

    return { deleteEvent, typeSelect, blogFilter };
  },
};
</script>

<style lang="scss" scoped>
.inner {
  padding-top: 90px;
  max-width: 960px;
  margin: 0 auto;
  .block {
    width: 100%;
    .list {
      margin-top: 15px;
      width: 100%;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      overflow: hidden;
      padding: 35px 30px;
      box-sizing: border-box;
      .name {
        float: left;
      }
      > a {
        float: right;
        margin-left: 20px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        svg {
          display: inline-block;
          margin-left: 5px;
        }
      }
      .edit {
        color: #1cb4bf;
      }
      .delete {
        color: red;
        cursor: pointer;
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;
    .selectCon {
      font-size: 14px;
    }
    .kindSelect {
      background: none;
      border: none;
      width: 170px;
    }
    .addProject {
      color: #1cb4bf;
      font-size: 16px;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
