<template>
  <div class="login">
    <div class="inner">
      <img src="~@/assets/images/Login/logo.svg" class="logo" alt="">
      <form>
        <input type="text" v-model="account" class="accountInput">
        <input type="password" v-model="password" class="accountInput">
        <input type="submit" @click="onSubmitEvent" class="btn" value="登入">
      </form>
    </div>
  </div>
</template>

<script>
//vue
import { ref } from '@vue/reactivity';
import {useRouter} from 'vue-router';

//api
import {loginApi} from '@/api/loginApi';

//plugin
import Cookies from 'js-cookie';

export default {
  setup:()=>{
    const router = useRouter();
    const account = ref('');
    const password = ref('');

    const onSubmitEvent = async(e)=>{
      e.preventDefault();
      try{
        const loginApiRes = await loginApi({account:account.value,password:password.value});
        if(loginApiRes.status === 'false'){
          alert('帳密不對');
          account.value = '';
          password.value = '';
        }else{
          Cookies.set('token',loginApiRes.message.token);
          Cookies.set('juicy3ID',loginApiRes.message.id);
          // console.log(loginApiRes);
          router.push({path:'/list'})
        }
      }catch(err){
        
      };
      
    };

    return{onSubmitEvent,password,account}
  }
}
</script>

<style lang="scss" scoped>
  .login{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .inner{
      width: 500px;
      text-align: center;
      .logo{
        width: 200px;
        margin-bottom: 30px;
      }
      .accountInput{
        width:100%;
        height: 40px;
        margin-bottom: 20px;
        padding: 0 10px;
        box-sizing: border-box;
      }
      .btn{
        background-color: #C4B09B;
        color: #fff;
        border: none;
        display: block;
        width:100%;
        height: 45px;
        border-radius: 3px;
        cursor: pointer;
        font-size: 20px;
        &:hover{
          opacity: 0.7;
        }
      }
    }
  }
</style>