<template>
  <!-- <router-link to="/">home</router-link><br><br><br>
  <router-link to="/list">list</router-link><br><br><br>
  <router-link to="/blog">inner</router-link> -->
  <Header v-if="showHeader" />
  <router-view></router-view>
</template>
<script>
import {useRoute,useRouter} from 'vue-router';
import { ref } from '@vue/reactivity';
import { onMounted, watch } from '@vue/runtime-core';

//api
import {checkApi} from '@/api/checkApi';

//components
import Header from '@/components/Header';

//plugin
import Cookies from 'js-cookie';

export default{
  components:{
    Header,
  },
  setup:()=>{

    const showHeader = ref(false);
    const route = useRoute();
    const router = useRouter();

    const isHeader = ()=>{
      if(route.path === '/'){
        showHeader.value = false;
      }else{
        showHeader.value = true;
      }
    };

    onMounted(async ()=>{
      if(!Cookies.get('token')){
        router.push({path:'/'})
      }else{
        const checkApiRes = await checkApi({token:Cookies.get('token'),id:Cookies.get('juicy3ID')});
        if(checkApiRes.status === 'false'){
          Cookies.remove('token');
          Cookies.remove('juicy3ID');
          router.push({path:'/'})
        }
      }
    })

    watch(route,()=>{
      isHeader();
    });


    return {showHeader}
  }

}
</script>

<style lang="scss">
  body{
    background-color: #F9F9F9;
    margin: 0;
    padding: 0;
  }
  *{
    box-sizing: border-box;
  }
</style>
